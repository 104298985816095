.page_container{
  padding: 10px;
}

.title{
  font-size: 3.5vw;
  text-align: center;
}



ion-col {
  text-align: left;
  border: solid 1px #fff;

}

.page_header{
  border: solid 0px
}
.page_title{
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.page_sub_title{
  font-size: 0.7rem;
}

.right_buttons{
  width:48px;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px; 
}