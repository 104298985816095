ion-menu{
  --ion-background-color: linear-gradient(to bottom right, #44525F, #1F252B);

  ion-list{
    height: 100%;
  }

  ion-item {
    color: rgb(255, 255, 255);
    --background: transparent;
    --border-color: rgb(255, 255, 255);
  }
  ion-content {

  }
}

.user_item{
  margin-top: 50px;
  margin-bottom: 70px;

  img{
    border: solid 1px #222
  }
}

.label_display_name{
  font-size: 22px;
}

.label_email{
  font-size: 12px;
}