.pic_container{
  width: 100%;
  padding-bottom: 50%;
  margin-bottom: 25%;
  position: relative;
  display: flex;
  justify-content: center;
}

.avatar_container {
  position: absolute;
  width: 50%;
  padding-bottom: 50%;
  display: flex;
  margin-top: 25%;
}

.avatar{
  width: 100%;
  height: 100%;
  border: solid 5px #fff;
  position: absolute;
}

.account_details{
  padding: 10px;

  h1{
    text-align: center;
  }
}

.button_container{
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px;
  left:0px;
}