
.ion-page{
  display: block;
  justify-content: start;
}

.header{
  text-align: left;
  margin-bottom: 40px;
}

.login_container{
  margin-bottom: 40px;


  div{
    border: solid 0px var(--ion-text-color);
    margin-bottom: 20px;
  }
  
}

.forgotten{
  margin-top:30px;
}

.login_box_outer{
  margin:80px 40px;
}

.options{
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .login_box{
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    height:100vh;
  }
  .login_box_inner{
    width:300px;
  }
  .login_box_outer{
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
}