.homepage{
  //height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.main_image{
  margin:-10px -10px 0 -10px;
}
.main_image img{
  margin:0 auto;
  display:block;
}
.image_light{display: block;}
.image_dark{display: none;}

@media (prefers-color-scheme: dark) {
  :root {
    /* Dark mode variables go here */
    .image_light{display: none;}
    .image_dark{display: block;}
  }
}

/* Fallback for older browsers or manual mode */
body.dark {
  /* Dark mode variables go here */
  .image_light{display: none;}
  .image_dark{display: block;}
}
.icon_menu{
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 16px;

  .icon_menu_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:33.3333333333%;
    margin-bottom:2em;

    .menu_icon{
      border: solid 3px var(--ion-color-step-550);
      border-radius: 50%;
      width: 70px;
      height: 70px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
}