
@media (max-width: 1921px) {
  ion-icon{
    font-size: 2.3rem;
  }
}

@media (max-width: 1441px) {
  ion-icon{
    font-size: 2rem;
  }
}

@media (max-width: 1025px) {
  ion-icon{
    font-size: 1.7rem;
  }
}


