
.link{
  text-decoration: none;
}

.contact_item{
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;


  ion-avatar{
    font-size: 32px;
    margin-right: 30px;
    text-align: center;
  }

  .contact_details{
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;


    ion-label{

    }

    .title{
      margin-bottom: 5px;
    }
  }
}



