.modal_content_wrapper{
  padding: 10px;
}

.modal_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;
  align-items: center;
  border-bottom: solid 1px var(--h2t-border-color);

  ion-icon{
    font-size: 2rem;
  }
}